import React, { Fragment } from 'react';
import favicon from '../../assets/favicon.ico'
import Helmet from 'react-helmet'
import Navbar from '../Navbar/Navbar';
import Footer from '../widgets/Footer';


import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';

const Layout = ({ children }) => {
    return (
        <Fragment>
            <Helmet>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Navbar />
            <div>{children}</div>
            <Footer />
        </Fragment>
        
    )
}

export default Layout
