export const MenuItems = [
    {
        title: 'Home',
        link: '/',
        cNames: 'navbar-links',
    },
    {
        title: 'Services',
        link: '/services',
        cNames: 'navbar-links'
    },
    {
        title: 'About',
        link: '/about',
        cNames: 'navbar-links'
    },
    {
        title: 'Contact',
        link: '/contact',
        cNames: 'navbar-links'
    },
    {
        title: 'Blog',
        link: 'https://blog.learningcurvebrampton.com/',
        cNames: 'navbar-links-mobile bg-yellow'
    },
];